import axios from 'axios'
import { CancelToken } from 'axios'
import { saveAs } from 'file-saver';

import Provider from '@provider'
import { MediaFileModel } from '@/models'
import { UPLOAD_FILES_URL } from '@/config'

export default {
  addFile: ({ form, progress = () => {} }) => {
    let cancel = () => {}
    return {
      callback: (resolve, reject) => {
        Provider.api
          .post(`${UPLOAD_FILES_URL}/files`, form, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            cancelToken: new CancelToken(function executor(c) {
              cancel = c
            }),
            onUploadProgress: progress,
          })
          .then(response => {
            if (response) {
              resolve(new MediaFileModel(response.data))
            }
          })
          .catch(reject)
      },

      cancel,
    }
  },

  addBulkFile: ({ form, progress = () => {} }) => {
    let cancel = () => {}
    return {
      callback: (resolve, reject) => {
        Provider.api
          .post(`${UPLOAD_FILES_URL}/massfiles`, form, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            cancelToken: new CancelToken(function executor(c) {
              cancel = c
            }),
            onUploadProgress: progress,
          })
          .then(response => resolve(response.data.map(e => new MediaFileModel(e))))
          .catch(reject)
      },

      cancel,
    }
  },

  getFile: id => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/files/${id}`)
        .then(response => resolve(new MediaFileModel(response.data)))
        .catch(reject)
    })
  },

  updateFile: ({ id, file, folderId, fileName, access }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`${UPLOAD_FILES_URL}/files/${id}`, {
          file,
          folder_id: folderId,
          file_name: fileName,
          access,
        })
        .then(response => resolve(new MediaFileModel(response.data)))
        .catch(reject)
    })
  },

  updateRemoteFile: ({ remoteId, remoteFolderId, fileName, access }) => {
    return Provider.api
      .put(`${UPLOAD_FILES_URL}/files`, {
        remote_item_id: remoteId,
        remote_folder_id: remoteFolderId,
        file_name: fileName,
        access,
      })
      .then(response => response.data);
  },

  deleteFile: id => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/files/${id}`)
        .then(response => resolve(response.data))
        .catch(reject)
    })
  },

  deleteRemoteFile: remoteFileId => {
    return Provider.api
      .delete(`/files/`, { params: { remote_item_id: remoteFileId } })
      .then(response => response.data);
  },

  downloadFile: ({ file, progress = () => {} }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(file.downloadUrl ?? file.url, {
          responseType: 'blob',
          mode: 'no-cors',
          cache: 'no-cache',
          withCredentials: false,
          headers: { 'Access-Control-Allow-Origin': '*' },
          params: {
            d: Date.now(),
          },
          onDownloadProgress: progressEvent => {
            let progressPercent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            progress(progressPercent < 100 ? progressPercent : 0, progressEvent)
          },
        })
        .then(response => {
          let blob = response.data;
          // let link = document.createElement('a')
          // link.href = window.URL.createObjectURL(blob)
          // link.download = file.fileName
          // link.click()

          const name = file.fileName;
          resolve({ blob, name });
          saveAs(blob, name);
        })
        .catch(reject)
    })
  },

  addFileLink: form => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post('/files/link', form)
        .then(response => resolve(new MediaFileModel(response.data)))
        .catch(reject)
    })
  },

  updateFileLink: (id, form) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/files/link/${id}`, form)
        .then(response => resolve(new MediaFileModel(response.data)))
        .catch(reject)
    })
  },
}
