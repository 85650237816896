export const prettyBytes = num => {
  if (typeof num !== 'number' || Number.isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  let
    exponent,
    unit,
    neg = num < 0,
    units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
};

export const isPdf = file => file.type === 'application/pdf';

export const scanFiles = async (item, arr, ignoreFolders) => {
  if (item.isFile && ignoreFolders.includes(item.name)) return;

  arr.push({ entry: item, id: null });

  if (item.isDirectory) {
    const entries = await new Promise(resolve => item.createReader().readEntries(resolve));

    for (let i = 0; i < entries.length; i++) {
      await scanFiles(entries[i], arr, ignoreFolders);
    }
  }
};
